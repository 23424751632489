body { padding:0; margin:0; height: 100%; overflow: hidden; }
html { height: 100% }

.app-root { 
    font-family: Verdana; 
    font-size: 0.8em; 
    margin: 0; 
    padding: 0px 30px; 
    height: 100%; 
    overflow: auto; 
    position: relative;
}

code { font-size: 1.2em; font-weight: bold; }

.icon { margin-right: 5px; vertical-align: middle; }
.header-cmd { color: #c5c5c5 }

.mono { font-family: monospace; font-size: 1.3em }

.button {
    padding: 10px;
    border: solid 1px white;
    border-radius: 5px;
    text-decoration: none;
    display: inline-block;
}

.button { padding: 5px;}
.button svg { margin: 0; margin-right: 5px; }
.button-large { padding: 10px; }

.button:hover { background: rgba(255, 255, 255, 0.2);}

.expressionInput { 
    padding: 3px; 
    outline: none;
    border: none;
    border-bottom: solid 1px rgba(255, 255, 255, 0.5);
    z-index: 100;
    padding-left: 15px;
    padding-bottom: 5px;
    width: 600px; 
    background: rgba(0, 0, 0, 0);
}

.hidden { display: none;}

.result { margin: 10px 10px 30px; }
.result .input { margin-bottom: 10px; }
.result .content { padding-left: 10px}
.result .cur { margin-right: 5px; }

.input-string { margin-right: 10px;}
.hashLink { text-decoration: none; margin-left: 8px; visibility: hidden; margin-right: 0; padding: 0; text-decoration: none; }
a.hashLink { font-size: 1.1em;}
.hashLink .icon { margin: 0;}
.hashLink:hover { text-decoration: underline; background: none; }
.result:hover .hashLink { visibility: visible }

.expression { font-size: 1.7em; font-family: monospace }
.expression button { font-family: monospace; font-size: 0.9em;}
.expression .label { font-weight:  bold; padding-right: 5px; text-align: right; }
.expression .bin { letter-spacing: 3px; }
.expression .info { font-size: 0.9em; color: teal; }
.expression .byte { margin: 0 3px; }
.expression-result td { border-top: dotted 1px gray; }
.expression .prefix { font-weight: normal; display: none; font-size: 0.9em }
.expression .other { font-size: 0.9em}
.expression .sign { text-align: right; padding-right: 5px;}

.flipable { cursor: pointer; opacity: 1 }
.flipable { cursor: pointer; opacity: 1 }
.flipable:hover { opacity: 0.8 }

.hex .prefix { display: inline; }

.indicator { padding: 0px 5px; background: transparent; border: none; cursor: pointer; vertical-align: middle; color:rgba(0, 0, 0, 0.25) }

.expanded { display: block;}
.collapsed { display: none;}

.error { color: maroon; }

.soft { opacity: 0.7 }

.small-text { font-size: 0.8em;}

#view { padding: 10px}

.cur { color: lightgray; }


button { border: none; }
button.link-button {text-decoration: underline;}

.settings-button {
    margin-left: -20px;
}

.undo button { 
    opacity: 0.4;
    padding: 0;
    margin-left: 5px;
}

.solid-border { border: solid 1px rgba(255, 255, 255, 0.8); border-radius: 5px;}
.solid-border-soft { border: solid 1px rgba(255, 255, 255, 0.5); border-radius: 5px;}


.zero { opacity: 0.5}
.dim-extra-bits .extra-bit { opacity: 0.1;}

/* Light */
.light { background: #fafafa; }
.light .solid-background {background: #fafafa;}
.light .header-cmd { color: #919191 }
.light a, .light a:visited, .light button { color: #222; }
.light .indicator { color: #ddd; }
.light .on { color: #121212; }
.light .prefix { color: #888}
.light .other { color: #bbb }
.light .hashLink, .light .hashLink:visited { color: #aaa; }
.light .hashLink:hover { color: #888 }
.light ul.top-links li:hover { background: #ddd }
.light .error { color: #da586d }
.light button.btn { color: black}
.light button.btn:hover { background: #ddd}
.light button.btn:disabled { color: #888; background-color: inherit; }
.light .accent1 { color:green}
.light .expressionInput { border-bottom: solid 1px rgba(0, 0, 0, 0.5);}
.light .button { border: solid 1px gray; }
.light .button:hover { background: rgba(0, 0, 0, 0.2);}
.light .solid-border { border: solid 1px gray;}
.light .donate-result-view .paypal-button { border: solid 1px gray; }
.light .accent1-border { border-color:green}

/* Dark */
.dark { background: #121212; color: white;}
.dark .solid-background {background: #121212;}
.dark .expression { color: white;}
.dark .expressionInput { color: white; }
.dark a, .dark a:visited, .dark button { color: white; }
.dark .indicator { color: #555; }
.dark .on { color: white; }
.dark .prefix { color: #999}
.dark  .other { color: #777;}
.dark .hashLink, .dark .hashLink:visited { color: #555 }
.dark .hashLink:hover { color: #999 }
.dark ul.top-links li:hover { background: #333 }
.dark .error { color: #da586d}
.dark button.btn { color: white}
.dark button.btn:hover { background: #333}
.dark button.btn:disabled { color: #999; background-color: inherit; }
.dark .accent1 { color:mediumseagreen}
.dark .accent1-border { border-color:mediumseagreen}

/*
    Midnight Theme
*/
.midnight { background: #2c3e50; color: white }
.midnight .solid-background {background: #2c3e50;}
.midnight .header-cmd { color: #7ea3b5 !important }
.midnight .expression { color: white;}
.midnight .expressionInput { color: white;}
.midnight a, .dark a:visited, .midnight button { color: white; }
.midnight .indicator { color: #85a0ad; }
.midnight .on { color: white; }
.midnight .prefix { color: #85a0ad}
.midnight  .other { color: #9FBAC7;}
.midnight .accent-background { background-color: #3b5268;}
.midnight .hashLink, .midnight .hashLink:visited { color: #85a0ad }
.midnight .hashLink:hover { color: #9FBAC7 }
.midnight ul.top-links li:hover { background: #132537 }
.midnight .error { color:#da586d}
.midnight .changelog .item-new .date { font-weight: bold }
.midnight button.btn { color: white}
.midnight button.btn:hover { background: #132537}
.midnight button.btn:disabled { color: #85a0ad; background-color: inherit; }
.midnight .accent1 { color:mediumseagreen}
.midnight .accent1-border { border-color:mediumseagreen}

button { 
    border: none;
    background: none;
    cursor: pointer;
}

button:focus {outline:0;}

/* Top Links Shrink */
@media (max-width: 800px) {
    .top-links .link-text { display: none }
    .debug-indicators { display: none;}
    .app-root {padding: 10px; }
}

.social-container{ position:fixed; bottom:20px; right:20px }

/* Remove margin space on body. Inline top links with header */
@media (max-width: 700px) {
    /*.expressionInput { width: 450px; }   */
}

/* Further shrink */
@media (max-width: 500px) {
    .expressionInput { width: 400px; }
    .top-links a { display: inline-block; padding: 5px 10px}
}

@media (max-width: 450px) {

    .expressionInput { width: 350px; }
}


#output {
    padding: 30px 30px;
}

.input-p {
    display:inline;
    z-index: 101;
    position: absolute;
    margin-right: -10px;
    margin-top: 3px;
    opacity: 0.5;
}

