.settings-container {
    padding: 20px;
    display: inline-block;
}

.settings-container button {
    text-decoration:  none;
}

.settings-container button svg {
    margin-right: 3px;
}

.settings-container .inner {
    padding: 15px 20px;
    border-radius: 5px;
    width: 500px;
}

.settings-container h3 { 
    margin-top: 0;
}

.settings-container .description {
    font-size: 0.85em;
    padding: 0;
    margin: 0;
    padding-left: 40px;
    opacity: 0.8;
}

.settings-container .setting {
    margin-bottom: 10px;
}