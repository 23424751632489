/* RecordInfo.css */

.record-info {
  position: relative;
  bottom: 0;
  width: 80%;
  margin: 0 auto;
  background-color: #121212;
  text-align: center;
  padding: 10px 0;
  border-top: 1px solid #e3e3e3;
}


